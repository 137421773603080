import { List, Space, Modal, message } from 'antd'
import React, { useEffect, useState } from 'react'
import {
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons'
import { getNoticeList, RemoveNotice } from '../../server/http'
import NoticeDrawer from '../../components/NoticeDrawer'
const { confirm } = Modal
const IconText = ({ icon, text }) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
)
function callback(key) {
    console.log(key)
}

const contentStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    padding: '20px',
}

function MyList() {
    const [list, setList] = useState([])
    const [visible, setVisible] = useState(false)
    const [showDrawer, setShowDrawer] = useState(false)
    const [formData, setFormData] = useState({})

    useEffect(() => {
        getData()
    }, [])
    function getData() {
        getNoticeList().then((res) => {
            setList(res.data)
        })
    }
    const onClose = () => {
        setVisible(false)
    }

    return (
        <div style={contentStyle}>
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        console.log(page)
                    },
                    pageSize: 5,
                }}
                dataSource={list}
                renderItem={(item) => (
                    <List.Item
                        key={item.id}
                        actions={[
                            <a
                                key="list-loadmore-delete"
                                href="1"
                                onClick={(e) => {
                                    e.preventDefault()
                                    let form_data = {}
                                    form_data = Object.assign({}, item)
                                    form_data.cover = [
                                        {
                                            name: 'aaa.png',
                                            url: item.cover,
                                            status: 'done',
                                            uid: '1',
                                        },
                                    ]

                                    setFormData(form_data)
                                    setShowDrawer(true)
                                    setVisible(true)
                                }}
                            >
                                <IconText
                                    icon={EditOutlined}
                                    text="编辑"
                                    key="list-vertical-edit-o"
                                />
                            </a>,
                            <a
                                key="list-loadmore-delete"
                                href="1"
                                onClick={(e) => {
                                    e.preventDefault()
                                    confirm({
                                        title: '确定删除公告吗?',
                                        icon: <ExclamationCircleOutlined />,

                                        onOk() {
                                            let params = {
                                                notice_id: item.id,
                                            }
                                            RemoveNotice(params).then((res) => {
                                                if (res.status === 200) {
                                                    message.success(res.message)
                                                    getNoticeList().then(
                                                        (res) => {
                                                            setList(res.data)
                                                        }
                                                    )
                                                } else {
                                                    message.error(res.message)
                                                }
                                            })
                                        },
                                        onCancel() {
                                            console.log('Cancel')
                                        },
                                    })
                                }}
                            >
                                <IconText
                                    icon={DeleteOutlined}
                                    text="删除"
                                    key="list-vertical-edit-o"
                                />
                            </a>,
                        ]}
                        extra={
                            <img
                                width={150}
                                height={75}
                                style={{ objectFit: 'cover' }}
                                alt="logo"
                                src={item.cover}
                            />
                        }
                    >
                        <List.Item.Meta
                            title={
                                <a href={item.href}>
                                    {item.title}({item.en_title})
                                </a>
                            }
                            description={item.create_at}
                        />
                        {/* <div dangerouslySetInnerHTML={{ __html: item.content }} style={contentStyle}></div> */}
                    </List.Item>
                )}
            />
            {showDrawer ? (
                <NoticeDrawer
                    onClose={onClose}
                    formData={formData}
                    visible={visible}
                    getData={getData}
                    setFormData={setFormData}
                />
            ) : (
                ''
            )}
        </div>
    )
}

export default MyList
