const menuList = [
    {
        title: '首页',
        key: '/',
        icon: 'DesktopOutlined',
        subMenu: false,
    },
    {
        title: '轮播图',
        key: '/banner',
        icon: 'PicLeftOutlined',
        subMenu: false,
    },

    // {
    //     title: '用户管理',
    //     key: '/user',
    //     icon: 'UserOutlined',
    //     subMenu: false,
    // },
    // {
    //     title: '购买管理',
    //     key: '/buys',
    //     icon: 'CarryOutOutlined',
    //     subMenu: false,
    // },
    // {
    //     title: '出售管理',
    //     key: '/sales',
    //     icon: 'CarryOutOutlined',
    //     subMenu: false,
    // },
    // {
    //     title: '资讯管理',
    //     key: '/news',
    //     icon: 'PicLeftOutlined',
    //     subMenu: true,
    //     list: [
    //         {
    //             title: '资讯列表',
    //             key: '/list',
    //         },
    //         {
    //             title: '添加资讯',
    //             key: '/add',
    //         },
    //     ],
    // },
    // {
    //     title: '公告管理',
    //     key: '/notice',
    //     icon: 'PicLeftOutlined',
    //     subMenu: true,
    //     list: [
    //         {
    //             title: '公告列表',
    //             key: '/list',
    //         },
    //         {
    //             title: '添加公告',
    //             key: '/add',
    //         },
    //     ],
    // },
    {
        title: '产品管理',
        key: '/product',
        icon: 'PicLeftOutlined',
        subMenu: true,
        list: [
            {
                title: '添加产品',
                key: '/add',
            },
            {
                title: '产品列表',
                key: '/list',
            },
        ],
    },
    {
        title: '工程管理',
        key: '/project',
        icon: 'PicLeftOutlined',
        subMenu: true,
        list: [
            {
                title: '添加工程',
                key: '/add',
            },
            {
                title: '工程列表',
                key: '/list',
            },
        ],
    },
    {
        title: '新闻管理',
        key: '/news',
        icon: 'PicLeftOutlined',
        subMenu: true,
        list: [
            {
                title: '添加新闻',
                key: '/add',
            },
            {
                title: '新闻列表',
                key: '/list',
            },
        ],
    },
]

export default menuList
