import React from 'react'

import { Card, Form, Input, Button, Select, Switch, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { project_categories } from '../../config/mosycle'
import { projectCreate } from '../../server/http'
import axios from 'axios'
const { Option } = Select
const normFile = (e) => {
    // console.log('Upload event:', e)
    if (Array.isArray(e)) {
        return e
    }

    return e && e.fileList
}

const IMAGE_TYPE_LIST = ['image/jpg', 'image/png', 'image/jpeg']

function AddProject() {
    // const uploadedList = [
    //     {
    //         uid: '-1',
    //         name: 'image.png',
    //         status: 'done',
    //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    //     },
    // ]

    const [images, setImages] = React.useState([])

    const onFinish = (values) => {
        // console.log('Success:', values)
        // return
        // console.log(images)
        let params = { ...values }
        params['images'] = images

        projectCreate(params).then((res) => {
            if (res.status === 200) {
                return message.success(res.message)
            }
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    const removeImg = (e) => {
        let new_array = images.filter((item) => {
            return item !== e.xhr
        })
        // console.log('删除后的数组', new_array)
        setImages((images) => {
            return new_array
        })
    }
    const handlePreview = (e) => {
        let src = 'http://nachome.ae/uploads/' + e.response.data
        // if (!src) {
        //   src = await new Promise(resolve => {
        // 	const reader = new FileReader();
        // 	reader.readAsDataURL(file.originFileObj);
        // 	reader.onload = () => resolve(reader.result);
        //   });
        // }

        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow.document.write(image.outerHTML)
    }
    const onImgFilesChange = async (e) => {
        let file = e.file

        if (IMAGE_TYPE_LIST.indexOf(file.type) < 0) {
            message.warn('请选择jpg,png,gif类型图片')
            return e.onError()
        }
        if (file.size > 5 * Math.pow(2, 20)) {
            message.error('图片大小应小于5M')
            return
        }
        let formData = new FormData()

        formData.append('file', file)
        formData.append('category', 'mosaic')
        try {
            // const res = await ImageUpload(formData)
            axios.post('/admin/api/upload', formData).then((res) => {
                if (res.status === 200) {
                    message.success(res.data.message)
                    setImages((images) => images.concat(res.data.data))
                    return e.onSuccess(res, res.data.data)
                } else {
                    message.warn(res.data.message)
                    return e.onError()
                }
            })
        } catch (error) {
            return e.onError()
        }
    }

    return (
        <Card title="添加工程">
            <Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                // size="large"
            >
                <Form.Item
                    label="工程名"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入产品名',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="category"
                    label="分类"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: '请选择1个分类',
                        },
                    ]}
                >
                    <Select showSearch allowClear placeholder="请选择1个分类">
                        {project_categories.map((item, index) => {
                            return (
                                <Option value={item} key={index}>
                                    {item}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="关联产品编号"
                    name="products"
                    rules={[
                        {
                            required: true,
                            message: '请输入产品编号 英文逗号隔开',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="show_index"
                    label="是否首页展示"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Form.Item label="工程图集">
                    <Form.Item
                        name="dragger"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        noStyle
                    >
                        <Upload.Dragger
                            listType="picture-card"
                            name="files"
                            customRequest={onImgFilesChange}
                            multiple
                            onRemove={removeImg}
                            showUploadList={true}
                            onPreview={handlePreview}
                            // defaultFileList={uploadedList}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                点击或者拖拽图片到此处
                            </p>
                            <p className="ant-upload-hint">支持多图上传</p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="desc"
                    rules={[
                        {
                            required: false,
                            message: '请输入工程描述',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>

                {/* <Form.Item
                    label="关联工程id"
                    name="project"
                    rules={[
                        {
                            required: false,
                            message: '请输入产品编号',
                        },
                    ]}
                >
                    <Input />
                </Form.Item> */}

                <Form.Item wrapperCol={{ offset: 11, span: 16 }}>
                    <Button type="primary" htmlType="submit" size="default">
                        创建工程
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
}

export default AddProject
