import {
    Card,
    Form,
    Input,
    Button,
    Select,
    Switch,
    Upload,
    Drawer,
    message,
    Checkbox,
    Row,
    Col,
} from 'antd'

import React from 'react'
import axios from 'axios'
import { InboxOutlined } from '@ant-design/icons'

import { productEdit } from '../server/http'
import {
    materials,
    usages,
    img_url,
    public_img_url,
    usage_icons,
} from '../config/mosycle'
const { Option } = Select
const normFile = (e) => {
    // console.log('Upload event:', e)
    if (Array.isArray(e)) {
        return e
    }

    return e && e.fileList
}
const IMAGE_TYPE_LIST = ['image/jpg', 'image/png', 'image/jpeg']

function ProductDrawer({ visible, onClose, formData, getData }) {
    let uploadedList = new Array()
    formData.images.split(',').forEach((val, index) => {
        let item_data = {}
        item_data['uid'] = index
        item_data['name'] = val
        item_data['url'] = img_url + val
        item_data['status'] = 'done'
        uploadedList.push(item_data)
    })
    console.log(formData.usage_icon)
    let icon_int_array = []
    if (formData.usage_icon.length > 0) {
        formData.usage_icon.forEach((item) => {
            icon_int_array.push(parseInt(item))
        })
    }

    formData.usage_icon = icon_int_array
    const [images, setImages] = React.useState(formData.images.split(','))
    // const [productId,setProductId] = React.useState(formData.id)
    const onFinish = (values) => {
        // console.log('Success:', values)
        // return
        // console.log(images)
        let params = { ...values }
        params['images'] = images
        params['id'] = formData.id
        console.log(params)
        productEdit(params).then((res) => {
            if (res.status === 200) {
                getData()
                onClose()
                return message.success(res.message)
            }
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    const removeImg = (e) => {
        let new_array = new Array()
        new_array = images.filter((item) => {
            if (e.hasOwnProperty('xhr')) {
                return item !== e.xhr
            } else {
                return item !== e.name
            }
        })
        // console.log('删除后的数组', images, new_array)
        setImages((images) => {
            return new_array
        })
    }
    const handlePreview = (e) => {
        let src = 'http://nachome.ae/uploads/' + e.response.data
        // if (!src) {
        //   src = await new Promise(resolve => {
        // 	const reader = new FileReader();
        // 	reader.readAsDataURL(file.originFileObj);
        // 	reader.onload = () => resolve(reader.result);
        //   });
        // }

        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow.document.write(image.outerHTML)
    }
    const onImgFilesChange = async (e) => {
        let file = e.file

        if (IMAGE_TYPE_LIST.indexOf(file.type) < 0) {
            message.warn('请选择jpg,png,gif类型图片')
            return e.onError()
        }
        if (file.size > 5 * Math.pow(2, 20)) {
            message.error('图片大小应小于5M')
            return
        }
        let formData = new FormData()

        formData.append('file', file)
        formData.append('category', 'mosaic')
        try {
            // const res = await ImageUpload(formData)
            axios.post('/admin/api/upload', formData).then((res) => {
                if (res.status === 200) {
                    message.success(res.data.message)
                    setImages((images) => images.concat(res.data.data))
                    return e.onSuccess(res, res.data.data)
                } else {
                    message.warn(res.data.message)
                    return e.onError()
                }
            })
        } catch (error) {
            return e.onError()
        }
    }

    return (
        <Drawer
            title="修改产品"
            placement="right"
            onClose={onClose}
            visible={visible}
            width={1000}
        >
            <Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ ...formData }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                // size="large"
            >
                <Form.Item
                    label="产品名"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: '请输入产品名',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="编号"
                    name="product_code"
                    rules={[
                        {
                            required: true,
                            message: '请输入产品编号',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="material"
                    label="材质"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'please select one material',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        allowClear
                        placeholder="please select one material"
                    >
                        {materials.map((item, index) => {
                            return (
                                <Option value={item} key={index}>
                                    {item}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="usage"
                    label="用途"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'please select one usage',
                        },
                    ]}
                >
                    <Select
                        showSearch
                        allowClear
                        placeholder="please select one usage"
                        mode="multiple"
                    >
                        {usages.map((item, index) => {
                            return (
                                <Option value={item} key={index}>
                                    {item}
                                </Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item name="usage_icon" label="标签图">
                    <Checkbox.Group>
                        <Row>
                            {usage_icons.map((item, index) => {
                                return (
                                    <Col span={6} key={index}>
                                        <img
                                            src={public_img_url + item}
                                            style={{ width: '50px' }}
                                        />
                                        <Checkbox
                                            value={index}
                                            style={{ lineHeight: '32px' }}
                                        ></Checkbox>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Checkbox.Group>
                </Form.Item>

                <Form.Item
                    label="表面"
                    name="surface"
                    rules={[
                        {
                            required: false,
                            message: 'please enter  the surface',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="颜色"
                    name="color"
                    rules={[
                        {
                            required: false,
                            message: 'please enter  the color',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="尺寸"
                    name="size"
                    rules={[
                        {
                            required: false,
                            message: 'please enter  the color',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="show_index"
                    label="是否首页展示"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Form.Item label="产品图集">
                    <Form.Item
                        name="dragger"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        noStyle
                    >
                        <Upload.Dragger
                            listType="picture-card"
                            name="files"
                            customRequest={onImgFilesChange}
                            multiple
                            onRemove={removeImg}
                            showUploadList={true}
                            onPreview={handlePreview}
                            defaultFileList={uploadedList}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                点击或者拖拽图片到此处
                            </p>
                            <p className="ant-upload-hint">支持多图上传</p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="desc"
                    rules={[
                        {
                            required: false,
                            message: '请输入产品编号',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>

                {/* <Form.Item
                    label="关联工程id"
                    name="project"
                    rules={[
                        {
                            required: false,
                            message: '请输入产品编号',
                        },
                    ]}
                >
                    <Input />
                </Form.Item> */}

                <Form.Item wrapperCol={{ offset: 11, span: 16 }}>
                    <Button type="primary" htmlType="submit" size="default">
                        确认修改
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default ProductDrawer
