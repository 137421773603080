import {
    Table,
    Card,
    Tag,
    Space,
    Button,
    Avatar,
    Popover,
    Input,
    Modal,
    message,
} from 'antd'

import {
    UserOutlined,
    SearchOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { getOrderList, getUserInfo, statusChange } from '../../server/http'

const { Search } = Input
const { confirm } = Modal
const popOverContent = (start_time, end_time) => {
    return <p>{start_time + '-' + end_time}</p>
}

const Sales = () => {
    const [list, setList] = useState([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [status, setStatus] = useState('')
    const [username, setUsername] = useState('')
    const [order_id, setOrderId] = useState('')
    const [temp, setTemp] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [userInfo, setUserInfo] = useState({})
    const history = useHistory()
    let searchInput = () => (
        <Search
            placeholder="input search text"
            enterButton="Search"
            size="small"
        />
    )
    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setTemp(e.target.value ? e.target.value : '')
                    }}
                    onPressEnter={(e) => {
                        if (dataIndex === 'order_id') {
                            setOrderId(e.target.value ? e.target.value : '')
                        } else {
                            setUsername(e.target.value ? e.target.value : '')
                        }
                    }}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            if (dataIndex === 'order_id') {
                                setOrderId(temp)
                            } else {
                                setUsername(temp)
                            }
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        搜索
                    </Button>
                    <Button
                        onClick={() => {
                            if (dataIndex === 'order_id') {
                                setOrderId('')
                            } else {
                                setUsername('')
                            }
                            setTemp('')
                            clearFilters()
                            console.log(clearFilters)
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        重置
                    </Button>
                </Space>
            </div>
        ),

        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100)
            }
        },
    })
    function handleUser(e, user_id) {
        e.preventDefault()

        let params = {
            user_id,
        }
        getUserInfo(params).then((res) => {
            if (res.status === 200) {
                setUserInfo(res.data[0])
                showModal()
            }
        })
    }
    const columns = [
        {
            title: '用户',
            dataIndex: ['user_id', 'username'],
            key: 'user_id',
            width: '16%',
            align: 'center',
            ...getColumnSearchProps('username'),
            render: (user_id, row) => (
                <>
                    <Space size="small">
                        <Avatar
                            style={{ backgroundColor: '#87d068' }}
                            icon={<UserOutlined />}
                            size="small"
                        />
                        <a
                            href="/"
                            onClick={(e) => {
                                handleUser(e, row['user_id'])
                            }}
                        >
                            {row['username']}
                        </a>
                    </Space>
                </>
            ),
        },
        {
            title: '订单Id',
            dataIndex: 'order_id',
            key: 'order_id',
            width: '10%',
            align: 'center',
            ...getColumnSearchProps('order_id'),
        },
        {
            title: 'Whats App',
            dataIndex: 'whats_app',
            key: 'whats_app',
            width: '10%',
            align: 'center',
        },
        {
            title: '单价',
            dataIndex: 'unit_price',
            key: 'unit_price',
            width: '10%',
            align: 'center',
            render: (unit_price) => (
                <span style={{ color: 'red' }}>{unit_price}</span>
            ),
        },
        {
            title: '数量',
            dataIndex: 'num',
            key: 'num',
            width: '10%',
            align: 'center',
        },
        {
            title: '金额',
            dataIndex: 'price',
            key: 'price',
            width: '5%',
            align: 'center',
        },
        {
            title: '预约时间',
            dataIndex: ['start_time', 'end_time'],
            key: 'start_time',
            width: '10%',
            align: 'center',
            render: (start_time, row) => {
                const start = row['start_time']
                const end_time = row['end_time']

                return (
                    <Popover
                        content={popOverContent(start, end_time)}
                        title="开始-结束"
                    >
                        <Button type="primary" size="small">
                            查看
                        </Button>
                    </Popover>
                )
            },
        },

        {
            title: '状态',
            key: 'status',
            dataIndex: 'status',
            width: '5%',
            align: 'center',
            filters: [
                { text: '已发布', value: 0 },
                { text: '已确认', value: 1 },
                { text: '已取消', value: 2 },
                { text: '已完成', value: 3 },
            ],

            render: (status) => (
                <>
                    <Tag
                        color={
                            status === 0
                                ? '#2db7f5'
                                : status === 1
                                ? '#f50'
                                : status === 2
                                ? ''
                                : '#108ee9'
                        }
                    >
                        {status === 0
                            ? '已发布'
                            : status === 1
                            ? '已确认'
                            : status === 2
                            ? '已取消'
                            : '已完成'}
                    </Tag>
                </>
            ),
        },
        {
            title: '发布时间',
            dataIndex: 'create_time',
            key: 'create_time',
            width: '10%',
            align: 'center',
        },
        {
            title: '操作',
            key: 'action',
            dataIndex: ['status', 'key', 'user_id'],
            width: '20%',
            align: 'center',
            render: (status, row) => (
                <Space size="small">
                    {row['status'] === 0 ? (
                        <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                                confirm({
                                    title: '确认预约吗',
                                    icon: <ExclamationCircleOutlined />,
                                    onOk() {
                                        let params = {
                                            status: 1,
                                            data_id: row['key'],
                                            tab: 'sales',
                                            user_id: row['user_id'],
                                        }
                                        statusChange(params).then((res) => {
                                            if (res.status === 200) {
                                                message.success(res.message)

                                                let param = {
                                                    page,
                                                    pageSize,
                                                    tab: 'sales',
                                                    status,
                                                    order_id,
                                                    username,
                                                }
                                                getData(param)

                                                history.go(0)
                                            } else {
                                                message.error(res.message)
                                            }
                                        })
                                    },
                                    onCancel() {
                                        console.log('Cancel')
                                    },
                                })
                            }}
                        >
                            确认
                        </Button>
                    ) : (
                        ''
                    )}
                    {row['status'] < 2 ? (
                        <Button
                            size="small"
                            type="dashed"
                            onClick={() => {
                                confirm({
                                    title: '确认取消预约吗',
                                    icon: <ExclamationCircleOutlined />,
                                    onOk() {
                                        let params = {
                                            status: 2,
                                            data_id: row['key'],
                                            tab: 'sales',
                                            user_id: row['user_id'],
                                        }
                                        statusChange(params).then((res) => {
                                            if (res.status === 200) {
                                                message.success(res.message)
                                                let param = {
                                                    page,
                                                    pageSize,
                                                    tab: 'sales',
                                                    status,
                                                    order_id,
                                                    username,
                                                }
                                                getData(param)
                                            } else {
                                                message.error(res.message)
                                            }
                                        })
                                    },
                                    onCancel() {
                                        console.log('Cancel')
                                    },
                                })
                            }}
                        >
                            取消
                        </Button>
                    ) : (
                        ''
                    )}
                    {row['status'] === 1 ? (
                        <Button
                            size="small"
                            type="danger"
                            onClick={() => {
                                confirm({
                                    title: '确认完成预约吗',
                                    icon: <ExclamationCircleOutlined />,
                                    onOk() {
                                        let params = {
                                            status: 3,
                                            data_id: row['key'],
                                            tab: 'sales',
                                            user_id: row['user_id'],
                                        }
                                        statusChange(params).then((res) => {
                                            if (res.status === 200) {
                                                message.success(res.message)
                                                let param = {
                                                    page,
                                                    pageSize,
                                                    tab: 'sales',
                                                    status,
                                                    order_id,
                                                    username,
                                                }
                                                getData(param)
                                            } else {
                                                message.error(res.message)
                                            }
                                        })
                                    },
                                    onCancel() {
                                        console.log('Cancel')
                                    },
                                })
                            }}
                        >
                            完成
                        </Button>
                    ) : (
                        ''
                    )}
                </Space>
            ),
        },
    ]

    useEffect(() => {
        let params = {
            page,
            pageSize,
            tab: 'sales',
            status,
            order_id,
            username,
        }
        getData(params)
    }, [page, pageSize, status, order_id, username])
    const getData = (params) => {
        getOrderList(params).then((res) => {
            if (res.status === 200) {
                setList(res.data.list)
                setTotal(res.data.total)
            }
        })
    }

    const handleTableChange = (pagination, filters) => {
        setStatus(filters['status'])
    }

    return (
        <Card style={{ height: '100%', paddingBottom: '20px' }}>
            <Modal
                title="用户信息"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>信誉值:{userInfo && userInfo.score ? userInfo.score : ''}</p>
                <p>
                    上次登录时间
                    {userInfo && userInfo.last_log_time
                        ? userInfo.last_log_time
                        : ''}
                </p>
                <p>
                    上次登录IP
                    {userInfo && userInfo.last_log_ip
                        ? userInfo.last_log_ip
                        : ''}
                </p>
            </Modal>
            <Table
                style={{ height: '95%' }}
                scroll={{ x: '100%' }}
                columns={columns}
                dataSource={list}
                onChange={handleTableChange}
                pagination={{
                    pageSize,
                    total,
                    showSizeChanger: true,
                    pageSizeOptions: ['8', '10', '20', '30'],
                    onChange: (page, pageSize) => {
                        setPage(page)
                        setPageSize(pageSize)
                    },
                }}
            />
        </Card>
    )
}

export default Sales
