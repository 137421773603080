import {
    Card,
    Table,
    Tag,
    Space,
    Input,
    Button,
    Popconfirm,
    message,
} from 'antd'
import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { getProductList, delProduct } from '../../server/http'
import { img_url } from '../../config/mosycle'
import ProductDrawer from '../../components/ProductDrawer'
const { Search } = Input

function ProductList() {
    let searchInput = () => (
        <Search
            placeholder="input search text"
            enterButton="Search"
            size="small"
        />
    )

    const [list, setList] = React.useState([])
    const [page, setPage] = React.useState(1)
    const [pageSize, setPageSize] = React.useState(10)
    const [total, setTotal] = React.useState(0)

    const [temp, setTemp] = React.useState('')
    const [productCode, setProductCode] = React.useState('')
    const [name, setName] = React.useState('')

    const [showDrawer, setShowDrawer] = React.useState(false)
    const [formData, setFormData] = React.useState({})
    const [visible, setVisible] = React.useState(false)
    React.useEffect(() => {
        let params = {
            page,
            // pageSize,
            // tab: 'buys',
            // status,
            productCode,
            name,
            // username,
        }
        getData(params)
    }, [page, productCode, name])
    function getData(params) {
        getProductList(params).then((res) => {
            setList(res.data.data)
            setTotal(res.data.total)
        })
    }
    const onClose = () => {
        setVisible(false)
        setShowDrawer(false)
        setFormData({})
    }
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node
                    }}
                    placeholder={
                        dataIndex === 'product_code' ? '输入产品编号' : ''
                    }
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setTemp(e.target.value ? e.target.value : '')
                    }}
                    onPressEnter={(e) => {
                        if (dataIndex === 'product_code') {
                            setProductCode(e.target.value ? e.target.value : '')
                        } else {
                            setName(e.target.value ? e.target.value : '')
                        }
                    }}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            if (dataIndex === 'product_code') {
                                setProductCode(temp)
                            } else {
                                setName(temp)
                            }
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        搜索
                    </Button>
                    <Button
                        onClick={() => {
                            if (dataIndex === 'product_code') {
                                setProductCode(temp)
                            } else {
                                setName(temp)
                            }
                            setTemp('')
                            clearFilters()
                            console.log(clearFilters)
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        重置
                    </Button>
                </Space>
            </div>
        ),

        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100)
            }
        },
    })
    const columns = [
        {
            title: '产品',
            dataIndex: 'thumb',
            key: 'thumb',
            render: (thumb) => (
                <a href={img_url + thumb} target="_blank" rel="noreferrer">
                    <img
                        src={img_url + thumb}
                        style={{ width: '30px' }}
                        alt=""
                    />
                </a>
            ),
        },

        {
            title: '编号',
            dataIndex: 'product_code',
            key: 'product_code',
            ...getColumnSearchProps('product_code'),
        },
        {
            title: '标题',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '材质',
            dataIndex: 'material',
            key: 'material',
            render: (material) => <Tag color="green">{material}</Tag>,
        },
        {
            title: '用途',
            dataIndex: 'usage',
            key: 'usage',
            render: (usage) => <Tag color="cyan">{usage}</Tag>,
        },
        {
            title: '尺寸',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: '表面',
            dataIndex: 'surface',
            key: 'surface',
            render: (surface) => <Tag color="blue">{surface}</Tag>,
        },
        {
            title: '颜色',
            dataIndex: 'color',
            key: 'color',
            render: (color) => <Tag color="default">{color}</Tag>,
        },
        {
            title: '操作',
            key: 'action',
            dataIndex: 'id',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        size="small"
                        onClick={(e) => {
                            // console.log(record)
                            setShowDrawer(true)
                            let params = record
                            params['usage'] = params['usage'].split(',')
                            params['usage_icon'] = params['usage_icon']
                                ? params['usage_icon'].split(',')
                                : []

                            setFormData(params)
                            setVisible(true)
                        }}
                    >
                        编辑
                    </Button>

                    <Popconfirm
                        title="确定要删除该数据吗?"
                        onConfirm={(e) => {
                            // console.log(text)

                            delProduct({ id: text }).then((res) => {
                                message.success(res.message)
                                getData()
                            })
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="danger" size="small">
                            删除
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    return (
        <Card title="产品列表">
            <Table
                columns={columns}
                dataSource={list}
                rowKey="id"
                pagination={{
                    pageSize,
                    total,
                    // showSizeChanger: true,
                    // pageSizeOptions: ['8', '10', '20', '30'],
                    onChange: (page, pageSize) => {
                        setPage(page)
                        setPageSize(pageSize)
                    },
                }}
            />

            {showDrawer ? (
                <ProductDrawer
                    onClose={onClose}
                    formData={formData}
                    visible={visible}
                    setShowDrawer={setShowDrawer}
                    getData={getData}
                    setFormData={setFormData}
                />
            ) : (
                ''
            )}
        </Card>
    )
}

export default ProductList
