import { Table } from 'antd'

const MyTable = ({
    columns,
    list,
    page,
    pageSize,
    handleTableChange,
    total,
    setPageSize,
    setPage,
}) => {
    return (
        <Table
            style={{ height: '95%' }}
            scroll={{ x: '100%' }}
            columns={columns}
            dataSource={list}
            onChange={handleTableChange}
            pagination={{
                pageSize,
                total,
                showSizeChanger: true,
                pageSizeOptions: ['8', '10', '20', '30'],
                onChange: (page, pageSize) => {
                    setPage(page)
                    setPageSize(pageSize)
                },
            }}
        />
    )
}

export default MyTable
