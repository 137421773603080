import React, { useRef } from 'react'
import { Drawer, Form, Input, Button, Upload, message } from 'antd'
import { Editor } from '@tinymce/tinymce-react'
import { UploadOutlined } from '@ant-design/icons'
import axios from 'axios'
import { EditNotice } from '../server/http'
import { useHistory } from 'react-router'

const formStyle = {
    padding: '20px',
    width: '90%',
}

const titleStyle = {
    textAlign: 'left',
    padding: '20px',
}

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
}

const tailLayout = {
    wrapperCol: { offset: 3, span: 16 },
}
const upload_url = `${process.env.REACT_APP_PROXY_URL}/admin/uploadImg`

//上传图片
const normFile = (e) => {
    if (Array.isArray(e)) {
        return e
    }
    return e && e.fileList
}

function NoticeDrawer({ onClose, visible, formData, getData, setFormData }) {
    const history = useHistory()

    const [img, setImg] = React.useState('')
    const editorRef = useRef(null)
    const editorRef2 = useRef(null)

    const onFinish = (values) => {
        let final_values = values
        final_values.id = formData.id
        final_values.cover[0] = img === '' ? values.cover[0]['url'] : img
        final_values.content = editorRef.current.getContent()
        final_values.en_content = editorRef2.current.getContent()
        EditNotice(final_values).then((res) => {
            if (res.status === 200) {
                // onClose()

                // setFormData({})
                history.go(0)
                message.success(res.message)
            } else {
                message.error(res.message)
            }
        })
    }
    const onFinishFailed = (err) => {
        console.log(err)
    }
    return (
        <Drawer
            title="修改文章"
            closable={true}
            onClose={onClose}
            visible={visible}
            width={1200}
        >
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={formStyle}
                initialValues={formData}
            >
                <h2 style={titleStyle}>添加公告</h2>
                <Form.Item
                    label="标题"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: '请输入标题',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="英文标题"
                    name="en_title"
                    rules={[
                        {
                            required: true,
                            message: '请输入标题',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="作者"
                    name="author_name"
                    rules={[
                        {
                            required: true,
                            message: '请输入副标题',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="封面图（建议尺寸 680px*348px）"
                    name="cover"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                        {
                            required: true,
                            message: '请上传封面图',
                        },
                    ]}
                >
                    <Upload
                        action="2"
                        listType="picture"
                        customRequest={async (data) => {
                            const formData = new FormData()
                            formData.append('file', data.file)

                            let result = await axios
                                .post(`${upload_url}`, formData)
                                .then((res) => {
                                    setImg(res.data.message)
                                    return data.onSuccess(res, data.file)
                                })

                            return result
                        }}
                        headers={{
                            Authorization: localStorage.u_age_token,
                        }}
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>上传封面</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="正文"
                    rules={[
                        {
                            required: true,
                            message: '请输入正文',
                        },
                    ]}
                >
                    <Editor
                        apiKey="jxwzgc0jb5q8dvzj9jqut9ru18gbthunxrmsgd3htqp51106"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={formData.content}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: ['image'],
                            toolbar:
                                'undo redo | formatselect | ' +
                                'bold italic backcolor image | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="英文正文"
                    rules={[
                        {
                            required: true,
                            message: '请输入正文',
                        },
                    ]}
                >
                    <Editor
                        name="en_content"
                        initialValue={formData.en_content}
                        apiKey="jxwzgc0jb5q8dvzj9jqut9ru18gbthunxrmsgd3htqp51106"
                        onInit={(evt, editor) => (editorRef2.current = editor)}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: ['image'],
                            toolbar:
                                'undo redo | formatselect | ' +
                                'bold italic backcolor image | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                    />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginTop: '20px' }}
                    >
                        修改
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default NoticeDrawer
