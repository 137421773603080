import { Form, Input, Button, Radio, Upload, Modal, message } from 'antd'
import ReactWEditor from 'wangeditor-for-react'
import { UploadOutlined } from '@ant-design/icons'
import { editNews, getNewsItem } from '../../server/http'
import React, { useState, useLayoutEffect } from 'react'
import axios from 'axios'
const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    overflow: 'scroll',
}

const formStyle = {
    padding: '20px',
    width: '90%',
}

const titleStyle = {
    textAlign: 'left',
    padding: '20px',
}

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
}

const tailLayout = {
    wrapperCol: { offset: 3, span: 16 },
}

//单选框配置
const radioOptions = [
    { label: 'U币动态', value: 1 },
    { label: '币圈咨询', value: 2 },
    { label: '迪拜新闻', value: 3 },
    { label: '吃喝玩乐', value: 4 },
]
const upload_url = `${process.env.REACT_APP_PROXY_URL}/admin/uploadImg`

//上传图片
const normFile = (e) => {
    if (Array.isArray(e)) {
        return e
    }
    return e && e.fileList
}

function EditNews(props) {
    const [item, setItem] = useState({ title: '123', content: '<h1>222</h1>' })

    const [visible, setVisible] = React.useState(false)
    const [confirmLoading, setConfirmLoading] = React.useState(false)
    const [formData, setFormData] = React.useState({})
    const editorConfig = {
        fontSizes: {
            'x-small': { name: '10px', value: '1' },
            small: { name: '12px', value: '2' },
            normal: { name: '16px', value: '3' },
            large: { name: '18px', value: '4' },
            'x-large': { name: '24px', value: '5' },
            'xx-large': { name: '32px', value: '6' },
            'xxx-large': { name: '48px', value: '7' },
        },
        excludeMenus: ['todo'],
        // uploadImgServer: 'http://localhost:8100/admin/contentImgUpload',
        // uploadVideoServer: '/upload-video',
        showLinkImg: false,
        customUploadImg: async (file, insertImgFn) => {
            const formData = new FormData()
            formData.append('file', file[0])

            let imgUrl = await axios
                .post(`${upload_url}`, formData)
                .then((res) => {
                    return res.data.message
                })

            insertImgFn(imgUrl)
        },
    }
    const [img, setImg] = React.useState('')
    useLayoutEffect(() => {
        const id = props.match.params.id
        async function getItem() {
            const res = await getNewsItem(id)
            setItem(res.data)
        }
        getItem()
        // console.log(item)
    }, [])

    const handleOk = () => {
        // setModalText('确认是否上传资讯。')
        setConfirmLoading(true)
        editNews(formData).then((res) => {
            if (res.status === 200) {
                setConfirmLoading(false)
                props.history.push('/news/list')
            } else {
                message.error(res.message)
                setConfirmLoading(false)
            }
        })
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const onFinish = (values) => {
        let final_values = values
        final_values.cover[0] = img
        final_values.id = props.match.params.id

        setFormData(final_values)
        setVisible(true)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <div style={containerStyle}>
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={item}
                style={formStyle}
            >
                <h2 style={titleStyle}>修改文章</h2>
                <Form.Item
                    label="标题"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: '请输入标题',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="英文标题"
                    name="en_title"
                    rules={[
                        {
                            required: true,
                            message: '请输入标题',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="副标题"
                    name="subtitle"
                    rules={[
                        {
                            required: true,
                            message: '请输入副标题',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="英文副标题"
                    name="en_subtitle"
                    rules={[
                        {
                            required: true,
                            message: '请输入副标题',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="类型"
                    name="type"
                    rules={[
                        {
                            required: true,
                            message: '请选择类型',
                        },
                    ]}
                >
                    <Radio.Group options={radioOptions} optionType="button" />
                </Form.Item>
                <Form.Item
                    label="作者"
                    name="author_name"
                    rules={[
                        {
                            required: true,
                            message: '请输入副标题',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="封面图"
                    name="cover"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                        {
                            required: true,
                            message: '请上传封面图',
                        },
                    ]}
                >
                    <Upload
                        action="2"
                        customRequest={async (data) => {
                            const formData = new FormData()
                            formData.append('file', data.file)

                            let result = await axios
                                .post(`${upload_url}`, formData)
                                .then((res) => {
                                    setImg(res.data.message)
                                    return data.onSuccess(res, data.file)
                                })

                            return result
                        }}
                        headers={{
                            Authorization: localStorage.u_age_token,
                        }}
                        listType="picture"
                        maxCount="1"
                    >
                        <Button icon={<UploadOutlined />}>上传封面</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="正文"
                    name="content"
                    rules={[
                        {
                            required: true,
                            message: '请输入正文',
                        },
                    ]}
                >
                    <ReactWEditor
                        value={'<h1>333</h1>'}
                        config={editorConfig}
                    />
                </Form.Item>
                <Form.Item
                    label="英文正文"
                    name="en_content"
                    rules={[
                        {
                            required: true,
                            message: '请输入正文',
                        },
                    ]}
                >
                    <ReactWEditor config={editorConfig} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginTop: '20px' }}
                    >
                        上传
                    </Button>
                </Form.Item>
            </Form>
            <ReactWEditor value={'<h1>333</h1>'} config={editorConfig} />
            <Modal
                title="确认"
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            ></Modal>
        </div>
    )
}

export default EditNews
