import { Form, Input, Button, Upload, Modal, message } from 'antd'

import { UploadOutlined } from '@ant-design/icons'
import { addNotice } from '../../server/http'
import React, { useRef } from 'react'
import axios from 'axios'

import { Editor } from '@tinymce/tinymce-react'

const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    overflow: 'scroll',
}

const formStyle = {
    padding: '20px',
    width: '90%',
}

const titleStyle = {
    textAlign: 'left',
    padding: '20px',
}

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
}

const tailLayout = {
    wrapperCol: { offset: 3, span: 16 },
}

const upload_url = `${process.env.REACT_APP_PROXY_URL}/admin/uploadImg`

//上传图片
const normFile = (e) => {
    if (Array.isArray(e)) {
        return e
    }
    return e && e.fileList
}

function Add(props) {
    const [visible, setVisible] = React.useState(false)
    const [confirmLoading, setConfirmLoading] = React.useState(false)
    const [formData, setFormData] = React.useState({})
    const [img, setImg] = React.useState('')
    const editorRef = useRef(null)
    const editorRef2 = useRef(null)
    const handleOk = () => {
        // setModalText('确认是否上传资讯。')
        setConfirmLoading(true)
        addNotice(formData).then((res) => {
            if (res.status === 200) {
                setConfirmLoading(false)
                props.history.push('/notice/list')
            } else {
                message.error(res.message)
                setConfirmLoading(false)
            }
        })
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const onFinish = (values) => {
        let final_values = values
        final_values.cover[0] = img
        final_values.content = editorRef.current.getContent()
        final_values.en_content = editorRef2.current.getContent()
        setFormData(final_values)
        setVisible(true)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <div style={containerStyle}>
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={formStyle}
            >
                <h2 style={titleStyle}>添加公告</h2>
                <Form.Item
                    label="标题"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: '请输入标题',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="英文标题"
                    name="en_title"
                    rules={[
                        {
                            required: true,
                            message: '请输入标题',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="作者"
                    name="author_name"
                    rules={[
                        {
                            required: true,
                            message: '请输入副标题',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="封面图（建议尺寸 680px*348px）"
                    name="cover"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                        {
                            required: true,
                            message: '请上传封面图',
                        },
                    ]}
                >
                    <Upload
                        action="2"
                        customRequest={async (data) => {
                            const formData = new FormData()
                            formData.append('file', data.file)

                            let result = await axios
                                .post(`${upload_url}`, formData)
                                .then((res) => {
                                    setImg(res.data.message)
                                    return data.onSuccess(res, data.file)
                                })

                            return result
                        }}
                        headers={{
                            Authorization: localStorage.u_age_token,
                        }}
                        listType="picture"
                        maxCount="1"
                    >
                        <Button icon={<UploadOutlined />}>上传封面</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    label="正文"
                    rules={[
                        {
                            required: true,
                            message: '请输入正文',
                        },
                    ]}
                >
                    <Editor
                        apiKey="jxwzgc0jb5q8dvzj9jqut9ru18gbthunxrmsgd3htqp51106"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        // onChange={(e) => {
                        //     console.log(e.target.getContent())
                        // }}
                        // onGetContent={handleGetContent}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: ['image'],
                            toolbar:
                                'undo redo | formatselect | ' +
                                'bold italic backcolor image | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="英文正文"
                    rules={[
                        {
                            required: true,
                            message: '请输入正文',
                        },
                    ]}
                >
                    <Editor
                        name="en_content"
                        apiKey="jxwzgc0jb5q8dvzj9jqut9ru18gbthunxrmsgd3htqp51106"
                        onInit={(evt, editor) => (editorRef2.current = editor)}
                        init={{
                            height: 500,
                            menubar: false,
                            plugins: ['image'],
                            toolbar:
                                'undo redo | formatselect | ' +
                                'bold italic backcolor image | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style:
                                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                    />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginTop: '20px' }}
                    >
                        上传
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                title="确认"
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            ></Modal>
        </div>
    )
}

export default Add
