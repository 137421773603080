import { Form, Input, Button } from 'antd'
import { withRouter } from 'react-router-dom'

import bgImg from '../../assets/login_bg.jpeg'
import { requestLogin } from '../../server/http'

const divStyle = {
    width: '100%',
    height: '100%',
    backgroundImage: 'url(' + bgImg + ')',
    backgroundSize: '100%,100%',
    backgroundRepeat: 'no-repeat',
}
const formStyle = {
    padding: '20px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    background: 'white',
    width: '400px',
    borderRadius: '5px',
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
}
const tailLayout = {
    wrapperCol: { offset: 10, span: 16 },
}
function Login(props) {
    const onFinish = (values) => {
        requestLogin(values).then((res) => {
            if (res.status === 200) {
                localStorage.setItem('mosycle_token', res.data.token)

                props.history.push('/')
            }
        })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <div style={divStyle}>
            <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={formStyle}
            >
                <h2 style={{ textAlign: 'center', marginTop: '24px' }}>
                    Mosycle 后台管理系统
                </h2>
                <Form.Item
                    label="账号"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: '请输入账号',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="密码"
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: '请输入密码',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginTop: '0px' }}
                    >
                        登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default withRouter(Login)
