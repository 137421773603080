import { get, post } from './api'

export const requestIndex = () => {
    return get('/index').then((res) => {
        // return res.data
        console.log(res)
    })
}

export const requestLogin = (data) => {
    return post('/login', data)
}
export const requestLogout = (data) => {
    return post('/logout', data)
}

export const addNews = (data) => {
    return post('/admin/news/add', data)
}
export const editNews = (data) => {
    return post('/admin/news/edit', data)
}

export const removeNews = (data) => {
    return post('/admin/news/remove', data)
}

export const getNewsList = (type) => {
    return get('/admin/news/list/' + type)
}

export const getNewsItem = (id) => {
    return get('/admin/news/item/' + id)
}

export const getOrderList = (data) => {
    return get('/admin/order/list', data)
}

export const statusChange = (data) => {
    return post('/admin/order/statusChange', data)
}

export const getUserInfo = (data) => {
    return get('/admin/user/info', data)
}
export const getUserList = (data) => {
    return get('/admin/user/list', data)
}

export const editUserInfo = (data) => {
    return post('/admin/user/editInfo', data)
}

export const getHomeData = (data) => {
    return get('/admin/home/data', data)
}

export const addNotice = (data) => {
    return post('/admin/notice/add', data)
}
export const getNoticeList = (data) => {
    return get('/admin/notice/list', data)
}
export const EditNotice = (data) => {
    return post('/admin/notice/edit', data)
}

export const RemoveNotice = (data) => {
    return post('/admin/notice/remove', data)
}

export const ImageUpload = (data) => {
    return post('/upload', data)
}
export const productCreate = (data) => {
    return post('/product/create', data)
}
export const getProductList = (data) => {
    return get('/product', data)
}

export const delProduct = (data) => {
    return post('/product/delete', data)
}

export const productEdit = (data) => {
    return post('/product/edit', data)
}

export const projectCreate = (data) => {
    return post('/project/create', data)
}
export const getProjectList = (data) => {
    return get('/project', data)
}

export const delProject = (data) => {
    return post('/project/delete', data)
}

export const projectEdit = (data) => {
    return post('/project/edit', data)
}
