import React from 'react'
import { Tabs } from 'antd'
import NewsDataList from '../../components/NewsDataList'
const { TabPane } = Tabs

function callback(key) {
    console.log(key)
}

const contentStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    padding: '20px',
}

function NewsList() {
    return (
        <div style={contentStyle}>
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="U币动态" key="1">
                    <NewsDataList type={'1'} />
                </TabPane>
                <TabPane tab="币圈资讯" key="2">
                    <NewsDataList type={'2'} />
                </TabPane>
                <TabPane tab="迪拜新闻" key="3">
                    <NewsDataList type={'3'} />
                </TabPane>
                <TabPane tab="吃喝玩乐" key="4">
                    <NewsDataList type={'4'} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default NewsList
