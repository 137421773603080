import { Card } from 'antd'
import { useState, useEffect } from 'react'
import { getHomeData } from '../../server/http'
// import { toMoney } from '../../config/tools'
const cardStyle = {
    textAlign: 'center',
}
function Dashboard() {
    const [data, setData] = useState({})
    useEffect(() => {
        // getData()
    }, [])

    function getData() {
        getHomeData().then((res) => {
            setData(res.data)
        })
    }

    return (
        <>
            <Card title="首页">
                欢迎回来
                <br />
            </Card>
        </>
    )
}

export default Dashboard
