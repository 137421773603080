import { List, Space, Modal, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { getNewsList, removeNews } from '../server/http'
// import MyDrawer from './NewsDrawer'
const { confirm } = Modal
const IconText = ({ icon, text }) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
)

function NewsDataList(props) {
    const [list, setList] = useState([])
    const [loadChild, setLoadChild] = useState(false)
    // const [formData, setFormData] = useState({})
    // const [visible, setVisible] = useState(false)
    useEffect(() => {
        const type = props.type
        getNewsList(type).then((res) => {
            setList(res.data)
        })
    }, [props.type])

    return (
        <>
            <List
                itemLayout="vertical"
                size="large"
                pagination={{
                    onChange: (page) => {
                        console.log(page)
                    },
                    pageSize: 5,
                }}
                dataSource={list}
                renderItem={(item) => (
                    <List.Item
                        key={item.id}
                        actions={[
                            <a
                                key="list-loadmore-delete"
                                href="1"
                                onClick={(e) => {
                                    e.preventDefault()
                                    confirm({
                                        title: '确定删除文章吗?',
                                        icon: <ExclamationCircleOutlined />,

                                        onOk() {
                                            let params = {
                                                news_id: item.id,
                                            }
                                            removeNews(params).then((res) => {
                                                if (res.status === 200) {
                                                    message.success(res.message)
                                                    getNewsList(
                                                        props.type
                                                    ).then((res) => {
                                                        setList(res.data)
                                                    })
                                                } else {
                                                    message.error(res.message)
                                                }
                                            })
                                        },
                                        onCancel() {
                                            console.log('Cancel')
                                        },
                                    })
                                }}
                            >
                                <IconText
                                    icon={DeleteOutlined}
                                    text="删除"
                                    key="list-vertical-edit-o"
                                />
                            </a>,
                        ]}
                        extra={
                            <img
                                width={150}
                                height={75}
                                style={{ objectFit: 'cover' }}
                                alt="logo"
                                src={item.cover}
                            />
                        }
                    >
                        <List.Item.Meta
                            title={<a href={item.href}>{item.title}</a>}
                            description={item.subtitle}
                        />
                        {/* <div dangerouslySetInnerHTML={{ __html: item.content }} style={contentStyle}></div> */}
                    </List.Item>
                )}
            />
        </>
    )
}

export default NewsDataList
