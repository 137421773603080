const materials = [
    'Ceramic Mosaics',
    'Ceramic Tiles',
    'Glass Mosaics',
    'Marble Mosaics',
    'Marble Tiles',
    'Shell Mosaics',
    'Slate Claddings',
    'Marble Claddings',
    'Cobbles',
    'Wash Basin',
    'Sandstone Tiles',
    'Artificial Stone',
]

const usages = [
    'Wall Cladding',
    'Swimming Pool',
    'Bathroom',
    'Living Room',
    'Kitchen',
    'Landscaping',
]
const project_categories = [
    'Restaurant',
    'Hotel',
    'Public Space',
    'Villa',
    'Shop/Office',
    'Garden',
    'Swimming Pool',
]
const usage_icons = [
    'indoor_floor_icon.png',
    'indoor_wall_icon.png',
    'outdoor_floor_icon.png',
    'outdoor_wall_icon.png',
    'show_floor_icon.png',
    'shower_wall_icon.png',
    'swimming_pool_icon.png',
    'tile_shade_icon.png',
]

const img_url = 'http://nachome.ae/uploads/'
const public_img_url = 'http://nachome.ae/images/'
export {
    materials,
    usages,
    project_categories,
    img_url,
    usage_icons,
    public_img_url,
}
