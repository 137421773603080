import { Switch, Redirect, withRouter, Route, Link } from 'react-router-dom'
import React, { useState } from 'react'
import { Layout, Menu, Avatar, Button } from 'antd'
import menuList from '../config/menu'
import LogoImg from '../assets/logo.jpeg'
import { requestLogout } from '../server/http'

import Dashboard from '../pages/DashBoard'
import User from '../pages/User'
import AddNews from '../pages/News/AddNews'
import NewsList from '../pages/News/NewsList'
import EditNews from '../pages/News/EditNews'
import BuyList from '../pages/Buys/index'
import SaleList from '../pages/Sales/index'
import NoticeList from '../pages/Notice/list'
import AddNotice from '../pages/Notice/add'
import AddProduct from '../pages/product/AddProduct'
import ProductList from '../pages/product/ProductList'
import AddProject from '../pages/project/AddProject'
import ProjectList from '../pages/project/ProjectList'
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
} from '@ant-design/icons'

const { Header, Sider, Content } = Layout
const { SubMenu } = Menu

function MyLayout(props) {
    const path = props.location.pathname
    const layoutStyle = {
        height: '100%',
    }
    const triggerStyle = {
        padding: '0px 24px',
        fontSize: '18px',
        lineHeight: '64px',
        cursor: 'pointer',
        transition: 'color 0.3s',
        // color: 'white',
    }
    const triggerOnHoverStyle = {
        color: '#1890ff',
        padding: '0 24px',
        fontSize: '18px',
        lineHeight: '64px',
        cursor: 'pointer',
        transition: 'color 0.3s',
    }
    const logoStyle = {
        height: '60px',
        margin: '0px 16px',
        // background: 'rgba(255, 255, 255, 0.3)',
        textAlign: 'center',
    }

    const [collapsed, setCollapsed] = useState(false)
    const [isHover, setIsHover] = useState(false)
    if (localStorage.getItem('mosycle_token') == null) {
        return <Redirect to="/login" />
    }
    function toggle() {
        setCollapsed(!collapsed)
    }
    function addHover() {
        setIsHover(true)
    }
    function removeHover() {
        setIsHover(false)
    }
    function Logout() {
        requestLogout().then((res) => {
            localStorage.removeItem('mosycle_token')
            props.history.push('/login')
        })
    }

    return (
        <Layout style={layoutStyle}>
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                theme="light"
            >
                <div className="logo" style={logoStyle}>
                    <img src={LogoImg} alt="" style={{ width: '70%' }} />
                </div>
                <Menu mode="inline" selectedKeys={[path]}>
                    {menuList.map((item, index) => {
                        if (item.subMenu) {
                            return (
                                <SubMenu
                                    key={item.key}
                                    icon={React.createElement(
                                        require('@ant-design/icons')[item.icon]
                                    )}
                                    title={item.title}
                                >
                                    {item.list.map((subItem) => {
                                        return (
                                            <Menu.Item key={subItem.key}>
                                                <Link
                                                    to={item.key + subItem.key}
                                                >
                                                    {subItem.title}
                                                </Link>
                                            </Menu.Item>
                                        )
                                    })}
                                </SubMenu>
                            )
                        } else {
                            return (
                                <Menu.Item
                                    key={item.key}
                                    icon={React.createElement(
                                        require('@ant-design/icons')[item.icon]
                                    )}
                                >
                                    <Link to={item.key}>{item.title}</Link>
                                </Menu.Item>
                            )
                        }
                    })}
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header
                    className="site-layout-background"
                    style={{
                        padding: 0,
                        background: 'white',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {React.createElement(
                        collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                        {
                            className: 'trigger',
                            onClick: toggle,
                            onMouseEnter: addHover,
                            onMouseLeave: removeHover,
                            style: isHover ? triggerOnHoverStyle : triggerStyle,
                        }
                    )}

                    <div style={{ paddingRight: '20px' }}>
                        <Avatar size="small" icon={<UserOutlined />} />
                        <span
                            style={{ paddingRight: '5px', paddingLeft: '5px' }}
                        >
                            admin
                        </span>
                        <span>
                            <Button type="link" info="true" onClick={Logout}>
                                退出登录
                            </Button>
                        </span>
                    </div>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '10px 10px',
                        padding: 10,
                        minHeight: 280,
                        maxHeight: '90vh',
                    }}
                >
                    <Switch>
                        {/* <Redirect from="/" exact to="/dashboard" /> */}
                        <Route path="/" exact component={Dashboard} />
                        <Route path="/user" component={User} />
                        <Route path="/news/list" component={NewsList} />
                        <Route path="/news/add" component={AddNews} />
                        <Route path="/news/edit/:id" component={EditNews} />
                        <Route path="/buys" component={BuyList} />
                        <Route path="/sales" component={SaleList} />
                        <Route path="/notice/list" component={NoticeList} />
                        <Route path="/notice/add" component={AddNotice} />
                        <Route path="/product/add" component={AddProduct} />
                        <Route path="/product/list" component={ProductList} />
                        <Route path="/project/add" component={AddProject} />
                        <Route path="/project/list" component={ProjectList} />
                        {/* <Route component={NoMatch} /> */}
                    </Switch>
                </Content>
            </Layout>
        </Layout>
    )
}

export default withRouter(MyLayout)
