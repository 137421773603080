import { Card, Button, Space, Input, Modal, message } from 'antd'
import MyTable from '../../components/Table'
import { useState, useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { getUserList, editUserInfo } from '../../server/http'

const { Search } = Input

function User() {
    const [list, setList] = useState([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const [total, setTotal] = useState(0)
    const [username, setUsername] = useState('')
    const [uid, setUid] = useState('')
    const [temp, setTemp] = useState('')
    const [currentId, setCurrentId] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [type, setType] = useState(false)
    const [inputVal, setInputVal] = useState('')
    let searchInput = () => (
        <Search
            placeholder="input search text"
            enterButton="Search"
            size="small"
        />
    )

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setTemp(e.target.value ? e.target.value : '')
                    }}
                    onPressEnter={(e) => {
                        if (dataIndex === 'uid') {
                            setUid(e.target.value ? e.target.value : '')
                        } else {
                            setUsername(e.target.value ? e.target.value : '')
                        }
                    }}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            if (dataIndex === 'uid') {
                                setUid(temp)
                            } else {
                                setUsername(temp)
                            }
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        搜索
                    </Button>
                    <Button
                        onClick={() => {
                            if (dataIndex === 'uid') {
                                setUid('')
                            } else {
                                setUsername('')
                            }
                            setTemp('')
                            clearFilters()
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        重置
                    </Button>
                </Space>
            </div>
        ),

        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100)
            }
        },
    })
    const columns = [
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
            align: 'center',
        },
        {
            title: 'UID',
            dataIndex: 'uid',
            key: 'uid',
            align: 'center',
            ...getColumnSearchProps('uid'),
        },
        {
            title: '账号',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
            ...getColumnSearchProps('username'),
        },
        {
            title: '信誉值',
            dataIndex: 'score',
            key: 'score',
            align: 'center',
        },
        {
            title: '上次登录时间',
            dataIndex: 'last_log_time',
            key: 'last_log_time',
            align: 'center',
        },
        {
            title: '上次登录IP',
            dataIndex: 'last_log_ip',
            key: 'last_log_ip',
            align: 'center',
        },
        {
            title: '操作',
            key: 'action',
            dataIndex: 'key',
            width: '20%',
            align: 'center',
            render: (key) => (
                <Space>
                    <Button
                        type="dashed"
                        size="small"
                        onClick={() => {
                            setCurrentId(key)
                            setType('password')
                            setShowModal(true)
                        }}
                    >
                        修改密码
                    </Button>
                    <Button
                        type="ghost"
                        size="small"
                        onClick={() => {
                            setCurrentId(key)
                            setType('score')
                            setShowModal(true)
                        }}
                    >
                        修改积分
                    </Button>
                </Space>
            ),
        },
    ]
    const handleCancel = () => {
        setShowModal(false)
    }

    useEffect(() => {
        let params = {
            page,
            pageSize,
            uid,
            username,
        }
        getData(params)
    }, [page, pageSize, uid, username])
    function handleTableChange() {}
    function getData(params) {
        getUserList(params).then((res) => {
            if (res.status === 200) {
                setList(res.data.list)
                setTotal(res.data.total)
            }
        })
    }

    return (
        <Card>
            <Modal
                title={type === 'password' ? '修改密码' : '修改积分'}
                visible={showModal}
                onOk={() => {
                    if (inputVal === '') {
                        return message.error('请输入修改值')
                    }
                    let params = {
                        inputVal,
                        type,
                        data_id: currentId,
                    }
                    editUserInfo(params).then((res) => {
                        if (res.status === 200) {
                            message.success(res.message)
                            let params = {
                                page,
                                pageSize,
                                uid,
                                username,
                            }
                            getData(params)
                        } else {
                            message.error(res.message)
                        }
                        setShowModal(false)
                    })
                }}
                onCancel={handleCancel}
            >
                <Input
                    type="text"
                    value={inputVal}
                    onChange={(e) => {
                        setInputVal(e.target.value)
                    }}
                />
            </Modal>
            <MyTable
                columns={columns}
                list={list}
                page={page}
                pageSize={pageSize}
                total={total}
                handleTableChange={handleTableChange}
                setPage={setPage}
                setPageSize={setPageSize}
            />
        </Card>
    )
}

export default User
