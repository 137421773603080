import { Switch, Route, HashRouter } from 'react-router-dom'
import 'antd/dist/antd.css'
import './index.css'
import Login from './pages/Login'
import Admin from './pages/Admin'
// import { notification } from 'antd'
// import { useEffect, useRef } from 'react'
// import noticeAudio from './assets/notice.m4a'
function App() {
    // const audioRef = useRef(null)
    // function play(e) {
    //     console.log(e)
    // }
    // useEffect(() => {
    //     const io = require('socket.io-client')
    //     const socket = io.connect(process.env.REACT_APP_SOCKET_URL)
    //     // console.log(process.env.REACT_APP_PROXY_URL)
    //     socket.emit('join', { username: 'admin', room: 'order' }, (error) => {
    //         console.log(error)
    //     })
    //     socket.on('send_notice', (data) => {
    //         console.log(data)
    //         notification.open({
    //             duration: null,
    //             message: '消息提示',
    //             description: `用户${data.username}${data.text}`,
    //         })
    //         audioRef.current.play()
    //     })
    //     // console.log('222')
    // }, [])

    return (
        <>
            <HashRouter>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/" component={Admin} />
                </Switch>
            </HashRouter>
            {/* <audio
                src={noticeAudio}
                ref={audioRef}
                controls="controls"
                preload="auto"
                style={{ display: 'none' }}
            ></audio> */}
        </>
    )
}

export default App
